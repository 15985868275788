// src/store/customer/customerSlice.js
'use client'

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fName: "",
  lName: "",
  email: "",
  address: "",
  phone: "",
  city: "",

  // Add other fields as needed
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerDetails: (state, action) => {
      const { fName, lName, email, address, phone, city } = action.payload;
      state.fName = fName;
      state.lName = lName;
      state.email = email;
      state.address = address;
      state.phone = phone;
      state.city = city;

      // Update other fields as needed
    },
    clearCustomerDetails: (state) => {
      state.fName = "";
      state.lName = "";
      state.phone = "";
      state.city = "";
      state.email = "";
      state.address = "";
      // Clear other fields as needed
    },
  },
});

export const { setCustomerDetails, clearCustomerDetails } =
  customerSlice.actions;

export default customerSlice.reducer;
