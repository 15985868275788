// src/store/index.js
'use client'

import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cart/cartSlice";
import customerReducer from "./customer/customerSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  storage,
  // Add any configuration options here
};

const rootReducer = combineReducers({
  cart: cartReducer,
  customer: customerReducer,
  // Add other reducers as needed
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

