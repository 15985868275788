// src/store/cart/cartSlice.js
'use client'

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find(
        (item) => item.product.id === newItem.product.id
      );

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
      } else {
        state.items.push(newItem);
      }
    },
    removeItem: (state, action) => {
      state.items = state.items.filter(
        (item) => item.product.id !== action.payload.id
      );
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;

      const itemToUpdate = state.items.find((item) => item.product.id === id);
      if (itemToUpdate) {
        itemToUpdate.quantity = quantity;
      } else {
        // Handle case where item is not found (optional)
        console.error(`Item with id ${id} not found in the cart.`);
      }
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const { addItem, removeItem, updateQuantity, clearCart } =
  cartSlice.actions;

export default cartSlice.reducer;
